:root {
  --w-menu-icon: 35px;
  --max-w-main-navigation-headline: calc(100% - 2 * var(--w-menu-icon));

  // --max-w-intro-headline: min(calc(100% - 2 * var(--w-menu-icon)), 68rem);
  // --max-w-intro-subtitle: min(calc(100% - 2 * var(--w-menu-icon)), 45rem);
  --max-w-intro-headline: calc(100% - 2 * var(--w-menu-icon));
  --max-w-intro-subtitle: calc(100% - 2rem);
}

@screen xs {
  :root {
    --w-menu-icon: 40px;
  }
}

@screen s {
  :root {
    --w-menu-icon: 60px;

    --max-w-intro-headline: min(calc(100% - 2 * var(--w-menu-icon)), 45rem);
    --max-w-intro-subtitle: min(
      calc(100% - 2 * var(--w-menu-icon) - 2rem),
      30rem
    );
  }
}

@screen 2xl {
  :root {
    --w-menu-icon: 70px;
  }
}
