@import "swiper/css";

@tailwind base;
@tailwind components;
@tailwind utilities;

/* TODO: When using tailwind's @layer directive, changes in the imported css files are not applied unless the index.scss is saved itself. Why is that? */
@import "variables.scss";
@import "typography.scss";

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
