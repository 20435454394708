@font-face {
  font-family: "TeleNeoWeb";
  src: url("../fonts/TeleNeoWeb-Regular.woff2") format("woff2"),
    url("../fonts/TeleNeoWeb-Regular.woff") format("woff"),
    url("../fonts/TeleNeoWeb-Regular.eot") format("eot");
}

@font-face {
  font-family: "TeleNeoWeb";
  src: url("../fonts/TeleNeoWeb-Medium.woff2") format("woff2"),
    url("../fonts/TeleNeoWeb-Medium.woff") format("woff"),
    url("../fonts/TeleNeoWeb-Medium.eot") format("eot");
  font-weight: 500;
}

:root {
  --font-size-body: 1rem;
  --leading-body: 1.15;

  --font-size-headline: 1.5rem;
  --leading-headline: 1.1;
}

@screen xs {
  :root {
    --font-size-headline: 1.75rem;
  }
}

@screen s {
  :root {
    --font-size-headline: 2.75rem;
  }
}

html {
  font-size: 87.5%;
  scroll-behavior: smooth;
  text-rendering: optimizeLegibility;

  @media (max-width: 20em) {
    font-size: calc(14 / 320 * 100vw);
  }
}

@screen s {
  html {
    font-size: 100%;
  }
}

@screen 2xl {
  html {
    font-size: 125%;
  }
}

h1,
h2,
h3,
h4,
h5,
h6 {
  @apply text-headline font-regular;
}
